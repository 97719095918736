import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import MapChart from "./MapChart";
import ReactTooltip from "react-tooltip";

const AreaSelector = ({langCode}) => {
  const [content, setContent] = useState("");
  const [STName, setSTName] = useState("");
  const [show, setShow] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  let history = useHistory();

  useEffect(() => {
    if (show && STName) {
      setShow(!show);
      history.push({
        pathname: "/statedetails",
        state: { show: show, STName: STName },
      });
    }
  }, [show, STName]);

  const checkIsDesktop = () => {
    const desktop = window.innerWidth > 1024;
    setIsDesktop(desktop);
    localStorage.setItem("showCityList", desktop);
  };

  useEffect(() => {
    checkIsDesktop();
    window.addEventListener("resize", checkIsDesktop);
    return () => {
      window.removeEventListener("resize", checkIsDesktop);
    };
  }, []);

  return (
    <React.Fragment>
      <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
        <MapChart
          setTooltipContent={setContent}
          setStateName={setSTName}
          setShowDistrict={setShow}
          isDesktop={isDesktop}
          langCode={langCode}
        />
        <ReactTooltip>{content}</ReactTooltip>
      </div>
    </React.Fragment>
  );
};

export default AreaSelector;
