import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import volume from "../images/volume.png";
import mute from "../images/mute.png";
import { TextToSpeech } from "@capacitor-community/text-to-speech";
import { useIonViewDidLeave } from "@ionic/react";

const MyTextToSpeech = forwardRef(({ speech, langCode }, ref) => {
  const [isSpeaking, setIsSpeaking] = useState(false);

  useIonViewDidLeave(() => {
    try {
      TextToSpeech.stop();
    } catch (error) {
      console.error("Error stopping speech:", error);
    }
  });

  useEffect(() => {
    console.log("isSpeaking changed to:", isSpeaking);
  }, [isSpeaking]);

  // Helper function to split long text into smaller chunks with respect to sentences
  const splitTextIntoChunks = (text, chunkSize = 450) => {
    const sentences = text.split(/(?<=[।])\s+/); // Split by Hindi sentence-ending punctuation (।)
    let chunks = [];
    let currentChunk = "";

    for (const sentence of sentences) {
      if ((currentChunk + sentence).length > chunkSize) {
        chunks.push(currentChunk.trim());
        currentChunk = sentence;
      } else {
        currentChunk += " " + sentence;
      }
    }

    if (currentChunk) {
      chunks.push(currentChunk.trim());
    }

    return chunks;
  };

  // Validate if the speech text is not empty
  const isValidSpeech = (text) => text && text.trim().length > 0;

  // Validate if the provided language code is supported
  const isValidLangCode = (code) => {
    const supportedLangCodes = ["en-US", "hi"];
    return supportedLangCodes.includes(code);
  };

  // Function to add a delay between speaking chunks
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const speak = async () => {
    if (!isValidSpeech(speech)) {
      console.error("Speech text is empty or invalid.");
      return;
    }

    const selectedLangCode = isValidLangCode(langCode) ? langCode : "en-US";
    console.log("Selected language:", selectedLangCode, "Speech:", speech);

    setIsSpeaking(true);

    // Split the speech text into smaller chunks
    const textChunks = splitTextIntoChunks(speech, 450); // Reduce chunk size for reliability

    // Loop through each chunk and speak it, adding a delay between chunks
    for (const chunk of textChunks) {
      try {
        await TextToSpeech.speak({
          text: chunk,
          lang: selectedLangCode,
          rate: 1.0,
          pitch: 1.0,
          volume: 1.0,
          category: "ambient",
        });

        // Add a longer delay to ensure the previous chunk is fully spoken
        await delay(500); // Increase delay to 1 second for stability
      } catch (error) {
        console.error("Error speaking text chunk:", error.message || error);
        break; // Stop speaking further if there's an error
      }
    }

    setIsSpeaking(false);
  };

  const stop = async () => {
    setIsSpeaking(false);
    try {
      await TextToSpeech.stop();
    } catch (error) {
      console.error("Error stopping speech:", error.message || error);
    }
  };

  // Expose the stop function to parent via ref
  useImperativeHandle(ref, () => ({
    stop,
  }));

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {isSpeaking ? (
        <img
          alt="Stop"
          src={mute}
          style={{ minWidth: "30px", width: "30px", height: "30px" }}
          onClick={stop}
        />
      ) : (
        <img
          alt="Speak"
          src={volume}
          style={{ minWidth: "30px", width: "30px", height: "30px" }}
          onClick={speak}
        />
      )}
    </div>
  );
});

export default MyTextToSpeech;
