import React, { useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Marker,
} from "react-simple-maps";

import { withRouter } from "react-router";
import india from "./topojsons/india.json";
import CityList from "../pages/cityList";
import { markers } from "../utility/commonMethods";

const MapChart = ({ setTooltipContent, history, isDesktop, langCode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const statePush = (name) => {
    let details = {
      state: name,
    };
    localStorage.setItem("placeDetails", JSON.stringify(details));
    setSelectedState(name); // Set the selected state

    if (isDesktop) {
      setIsOpen(!isOpen);
    } else {
      history.push(`./citylist`);
    }
  };

  const stateOnClick = (label) => {
    statePush(label);
  };

  const handleCloseModal = () => {
    setIsOpen(!isOpen); // Close the modal
  };

  const mapWidth = 150;
  const mapHeight = 150;
  return (
    <>
      <ComposableMap
        data-tip=""
        projection="geoMercator"
        width={mapWidth}
        height={mapHeight}
        projectionConfig={{ scale: 220 }}
      >
        <ZoomableGroup
          zoom={1}
          center={[79.2, 25.2]}
          minZoom={1}
          maxZoom={4}
          translateExtent={[
            [320, -mapHeight + 50],
            [mapWidth * 2 + 160, mapHeight / 2],
          ]}
        >
          <Geographies geography={india}>
            {({ geographies }) =>
              geographies?.map((geo, index) => {
                return (
                  <Geography
                    key={geo?.rsmKey}
                    geography={geo}
                    onMouseEnter={() => {
                      const { ST_NM } = geo?.properties;
                      setTooltipContent(`${ST_NM}`);
                    }}
                    onMouseLeave={() => {
                      setTooltipContent("");
                    }}
                    onClick={() => {
                      const { ST_NM } = geo?.properties;
                      stateOnClick(`${ST_NM}`);
                    }}
                    style={{
                      default: {
                        fill: markers[index]?.color,
                        outline: "none",
                      },
                      hover: {
                        fill: "forestgreen",
                        outline: "none",
                      },
                      pressed: {
                        fill: "#2E8B57",
                        outline: "none",
                      },
                    }}
                  />
                );
              })
            }
          </Geographies>
          {markers?.map(
            ({ name, name_lang, coordinates, markerOffset, label }) => (
              <Marker key={name} coordinates={coordinates}>
                <text
                  textAnchor="middle"
                  y={markerOffset}
                  style={{
                    fill: "black",
                    fontSize: "0.09rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    stateOnClick(label);
                  }}
                >
                  {name_lang?.[langCode] ? name_lang?.[langCode] : name}
                </text>
              </Marker>
            )
          )}
        </ZoomableGroup>
      </ComposableMap>
      {isOpen && selectedState && (
        <CityList key={selectedState} onCloseModal={handleCloseModal} />
      )}
      {/* Pass key to force re-render */}
    </>
  );
};

export default withRouter(MapChart);
