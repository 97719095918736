import React, { useEffect, useState } from "react";
import { IonRow, IonCol, IonIcon } from "@ionic/react";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { isBrowser, isMobile } from "react-device-detect";
import { APIFY, HTTP_CLIENT } from "../services/Api";
import { getLangLabel } from "../utility/languageTranslator";
import { getStateList } from "../modalconfig";
import { closeCircle, search } from "ionicons/icons";
import { markers } from "../utility/commonMethods";
const removeUTList = [
  "Delhi (UT)",
  "Andaman & Nicobar Island (UT)",
  "Ladakh (UT)",
  "J & K (UT)",
  "Lakshadweep (UT)",
];

const SearchInput = ({ history, langCode }) => {
  const { transcript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();
  const [searchInput, setSearchInput] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [recognizedText, setRecognizedText] = useState("");
  const [places, setPlaces] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [speechRecognition, setSpeechRecognition] = useState({
    SpeechRecognitionModule: null,
    startListening: null,
    handleStartListening: null,
    startListen: null,
    stopListen: null,
  });

  useEffect(() => {
    const speechRecognitionFunctions = deviceDetect();
    setSpeechRecognition(speechRecognitionFunctions);
  }, [isMobile, isBrowser]);

  const deviceDetect = () => {
    console.log("deviceDetected is called.");
    let SpeechRecognitionModule;
    let startListening, handleStartListening, startListen, stopListen;

    if (isMobile) {
      const CapacitorSpeechRecognition =
        require("@capacitor-community/speech-recognition").SpeechRecognition;
      SpeechRecognitionModule = CapacitorSpeechRecognition;

      startListening = () => {
        const available = CapacitorSpeechRecognition.available();
        try {
          if (available) {
            CapacitorSpeechRecognition.start({
              language: "en-US",
              maxResults: 2,
              prompt: "Say something",
              partialResults: true,
              popup: false,
            });
            setIsListening(true);

            CapacitorSpeechRecognition.addListener("partialResults", (data) => {
              if (data.matches && data.matches.length > 0) {
                console.log("Recognized text:", data.matches[0]);
                setRecognizedText(data.matches[0]);
              }
            });
          }
        } catch (error) {
          console.error("Failed to start listening:", error);
        }
      };

      handleStartListening = async () => {
        try {
          setRecognizedText("");
          startListening();
          setTimeout(() => {
            CapacitorSpeechRecognition.stop();
            console.log("Stopped listening after 3 seconds");
            setIsListening(false);
          }, 3000);
        } catch (error) {
          console.error("Failed to start listening:", error);
        }
      };
    } else if (isBrowser) {
      SpeechRecognitionModule = SpeechRecognition;

      startListen = () => {
        if (browserSupportsSpeechRecognition) {
          SpeechRecognition.startListening();
          setIsListening(true);
        } else {
          console.log("Speech recognition not supported in this browser.");
        }
      };

      stopListen = () => {
        try {
          setRecognizedText("");
          startListen();
          setTimeout(() => {
            SpeechRecognition.stopListening();
            console.log("Stopped listening after 3 seconds");
            setIsListening(false);
          }, 3000);
        } catch (error) {
          console.error("Failed to stop listening:", error);
        }
      };
    }

    return {
      SpeechRecognitionModule,
      startListening,
      handleStartListening,
      startListen,
      stopListen,
    };
  };

  const handleOnSearch = (text) => {
    console.log("this is handle");
    setSearchInput(text || "");
    getPlaces(text);
  };

  useEffect(() => {
    if (recognizedText || transcript) {
      console.log("useEffect transcript", recognizedText, "tran=>", transcript);
      getPlaces(isMobile ? recognizedText : transcript);
    } else {
      console.log(
        "this is else part",
        typeof recognizedText + "second" + typeof transcript
      );
    }
  }, [isListening, recognizedText, transcript]);

  useEffect(() => {
    setSearchInput(isMobile ? recognizedText : transcript);
  }, [isListening, isMobile, recognizedText, transcript]);

  const handleOnSelect = (item) => {
    if (item._id) {
      setPlaces([]);
      setSearchInput("");
      localStorage.setItem("placeDetails", JSON.stringify(item));
      if (item?.cityKey) {
        history.push(`/placeinformation`);
      } else {
        history.push(`/particularplaceinformation`);
      }
    } else {
      if (item?.stateType) {
        console.log(
          "testingsunil.." +
            item?.name +
            ",,," +
            item?.name?.replace(/\s*\(UT\)\s*/, "").trim() +
            ",,,sdfsdfsd"
        );
        localStorage.setItem(
          "placeDetails",
          JSON.stringify({
            state: removeUTList.includes(item?.name)
              ? item?.name
                  ?.replace("J & K (UT)", "Jammu and Kashmir")
                  ?.replace(" (UT)", "")
                  .trim()
              : item?.name,
          })
        );
        history.push(`/cityList`);
        setPlaces([]);
        setSearchInput("");
      }
    }
  };
  const getCityData = async () => {
    let cityListData = [];
    const cities = await JSON.parse(localStorage.getItem("cities"));
    if (!cities && cityList?.length === 0) {
      const res1 = await getStateList({});
      cityListData = res1?.city?.map((obj) => {
        obj.name = obj?.city;
        return obj;
      });
      setCityList(cityListData);
      localStorage.setItem("cities", JSON.stringify(res1?.city || []));
    }
    if (cities && cityList?.length === 0) {
      cityListData = cities?.map((obj) => {
        obj.name = obj?.city;
        return obj;
      });
      setCityList(cityListData);
    }
    if (cityList?.length > 0) cityListData = cityList;
    return cityListData;
  };
  async function getPlaces(text) {
    if (text?.length > 0) {
      await HTTP_CLIENT(APIFY("/places"), "GET", null, {
        name: text,
        limit: 15,
      })
        .then((data) => {
          getCityData().then((cityListData) => {
            const states = markers
              ?.filter((state) =>
                state?.name?.toLowerCase().includes(text?.toLowerCase())
              )
              ?.map((state) => {
                return { name: state?.name, stateType: true };
              });

            const cityListNow = cityListData?.filter((obj) =>
              obj?.city?.toLowerCase().includes(text?.toLowerCase())
            );
            setPlaces([...states, ...cityListNow, ...data?.data]);
          });
        })
        .catch((err) => {});
    }
  }
  const handleOnClear = () => {
    setSearchInput("");
    setPlaces([]);
  };
  return (
    <div>
      <div className="top-banner">
        <div className="banr-box" style={{ zIndex: 9, width: "100%" }}>
          <IonRow
            className={places?.length > 0 ? "typed" : ""}
            style={{
              position: "relative",
              backgroundColor: "#fff",
              border: "1px solid #dfe1e5",
              boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
              marginLeft: "20px",
              marginRight: "20px",
              borderRadius: "24px",
            }}
          >
            <IonCol
              size="auto"
              style={{
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: "10px",
                paddingRight: 0,
                display: "flex",
                alignItems: "center",
                fontSize: "20px",
              }}
            >
              <IonIcon icon={search} style={{ color: "grey" }} />
            </IonCol>
            <IonCol
              style={{
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <input
                style={{
                  paddingLeft: "10px",
                  width: "100%",
                  height: "44px",
                  border: "none",
                  borderRadius: "24px",
                  backgroundColor: "white",
                  boxShadow: "none",
                  color: "#212121",
                  fontSize: "16px",
                  fontFamily: "Arial",
                  placeholderColor: "grey",
                }}
                value={searchInput}
                type="text"
                onChange={(e) => handleOnSearch(e.target.value)}
                placeholder={getLangLabel(langCode)?.search}
              />
            </IonCol>
            {searchInput?.length > 0 && (
              <IonCol
                size="auto"
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingLeft: 0,
                  paddingRight: "10px",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "20px",
                }}
              >
                <IonIcon icon={closeCircle} onClick={() => handleOnClear()} />
              </IonCol>
            )}
            {isListening && getLangLabel(langCode)?.listening}
            <IonCol
              className="thin-col"
              style={{
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <button
                onClick={
                  isMobile
                    ? speechRecognition.handleStartListening
                    : speechRecognition.stopListen
                }
                style={{
                  minHeight: "44px",
                  backgroundColor: "#fff",
                  color: "#000",
                  borderRadius: "24px",
                }}
              >
                {!isListening && (
                  <KeyboardVoiceIcon style={{ color: "#000" }} />
                )}
              </button>
            </IonCol>
            {places?.length > 0 && (
              <div className="places-suggestions" style={{ marginTop: "10px" }}>
                {places.map((obj) => (
                  <>
                    <IonRow
                      className="resultList"
                      key={obj._id}
                      onClick={() => handleOnSelect(obj)}
                    >
                      <IonCol
                        size="auto"
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          paddingLeft: "10px",
                          paddingRight: 0,
                          display: "flex",
                          alignItems: "center",
                          fontSize: "20px",
                        }}
                      >
                        <IonIcon icon={search} style={{ color: "grey" }} />
                      </IonCol>
                      <IonCol
                        style={{
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        {obj.name}
                      </IonCol>
                    </IonRow>
                  </>
                ))}
              </div>
            )}
          </IonRow>
        </div>
      </div>
    </div>
  );
};

export default SearchInput;
