import React, { useState, useEffect } from "react";
import { IonPage, IonHeader, IonToolbar, IonContent } from "@ionic/react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useHistory } from "react-router-dom";
import { APIFY, HTTP_CLIENT } from "../../services/Api";
import { Link } from "react-router-dom";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import DesktopHeader from "../../components/DesktopHeader";
import logo from "../../images/logo.png";
import { useLocation } from "react-router-dom";

const removeHtmlTags = (str) => {
  if (!str || typeof str !== "string") return "";
  return str?.replace(/<[^>]*>/g, "");
};

const Blogs = () => {
  const location = useLocation();
  const history = useHistory();
  const [blogs, setBlogs] = useState([]);
  const [langCode, setlangCode] = useState("en");

  const getBlogs = async () => {
    try {
      const res = await HTTP_CLIENT(APIFY("/blogs"), "GET");
      console.log("success", res);
      setBlogs(res.data);
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    setBlogs([]);
    const languageCode = localStorage.getItem("languageCode");
    setlangCode(languageCode || "en");
    getBlogs();
  }, [location.key]);

  const setAnalyticsScreen = async () => {
    try {
      await FirebaseAnalytics.setCurrentScreen({
        screenName: `Blogs Page`,
        screenClass: "Blogs Page",
      });
      await FirebaseAnalytics.logEvent({
        name: "screen_view",
        params: {
          screen_name: `Blogs Page`,
          screen_class: "Blogs Page",
        },
      });
      console.log("Screen view event logged");
    } catch (error) {
      console.error("Error logging screen view event:", error);
    }
  };
  useEffect(() => {
    setAnalyticsScreen();
  }, []);

  return (
    <IonPage>
      <IonHeader style={{ background: "#fff" }}>
        <IonToolbar className="t-bar">
          <div className="top-nav">
            <div className="nav_inner">
              <ArrowBackOutlinedIcon
                className="color-white"
                onClick={() => history.goBack()}
              />
              <h4 className="title-txt">Blogs</h4>

              <img
                onClick={() => history.push("/home")}
                src={logo}
                width={50}
                height={40}
                alt=""
              />
            </div>
          </div>
        </IonToolbar>
        <DesktopHeader />
      </IonHeader>
      <IonContent>
        <div className="flex-head">
          <div className="container">
            <h4 className="inner-title">Blogs</h4>
          </div>
        </div>
        <div className="bg-grey">
          <div className="img-card">
            <div className="padding-box">
              <div className="container">
                <div className="gridCard">
                  {blogs.length === 0 ? (
                    <h4 style={{ paddingLeft: "8px" }}>No Blogs to show</h4>
                  ) : (
                    blogs.map((blog) => (
                      <div className="cardItem" key={blog._id}>
                        <Link
                          to={`/blogdetails/${blog._id}`}
                          className="blog-card" style={{marginBottom: "10px"}}
                        >
                          <img src={blog.img} alt={blog.title} />
                          <h2>
                            {blog?.title_lang?.[langCode]
                              ? blog?.title_lang?.[langCode]
                              : blog?.title}
                          </h2>
                          <p>
                            {blog?.description_lang?.[langCode]
                              ? removeHtmlTags(
                                  blog?.description_lang?.[langCode]
                                )
                              : removeHtmlTags(blog?.description)}
                          </p>
                        </Link>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Blogs;
