/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";

const SplashScreen = () => {
  const [isFading, setIsFading] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFading(true);
      setTimeout(() => {
        setIsVisible(false);
      }, 500);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  if (!isVisible) return null;
  return (
    <>
      <div className={`customSplashScreen ${isFading ? "fade-out" : ""}`}>
        <img alt=" " src="./assets/icon/logo.png" className="splash-logo" />
      </div>
    </>
  );
};

export default SplashScreen;
