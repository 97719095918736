import React, { useEffect, useState } from "react";
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonItem,
  IonLoading,
} from "@ionic/react";
import { withRouter } from "react-router";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import "react-multi-carousel/lib/styles.css";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import DesktopHeader from "../../components/DesktopHeader";
import FooterMenu from "../../components/FooterMenu";

const ViewDetails = ({ history }) => {
  const [filter, setFilter] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [langCode, setlangCode] = useState("en");
  const setAnalyticsScreen = async () => {
    try {
      await FirebaseAnalytics.setCurrentScreen({
        screenName: `Filter: ${filter?.label}`,
        screenClass: "Filter Page",
      });
      await FirebaseAnalytics.logEvent({
        name: "screen_view",
        params: {
          screen_name: `Filter: ${filter?.label}`,
          screen_class: "Filter Page",
        },
      });
      console.log("Screen view event logged");
    } catch (error) {
      console.error("Error logging screen view event:", error);
    }
  };
  useEffect(() => {
    const languageCode = localStorage.getItem("languageCode");
    setlangCode(languageCode || "en");
    if (filter?.label) setAnalyticsScreen();
  }, [filter?.label]);

  useIonViewDidEnter(async () => {
    const filterDetails =
      JSON.parse(localStorage.getItem("filterDetails")) || [];
    setFilter(filterDetails);
    setShowLoading(false);
  });

  useIonViewDidLeave(() => {
    setFilter([]);
  });

  return (
    <IonPage>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      <IonHeader style={{ background: "#fff" }}>
        <IonToolbar className="t-bar">
          <div className="top-nav">
            <div className="nav_inner">
              <ArrowBackIosNewRoundedIcon
                className=""
                onClick={() => history.goBack()}
              />
               <h4 className="title-txt">
                {filter?.label_lang?.[langCode]
                ? filter?.label_lang?.[langCode]
                : filter?.label}</h4>
              <ArrowBackIosNewRoundedIcon
                className="visibilityHidden"
              />
            </div>
          </div>
        </IonToolbar>
        <DesktopHeader />
      </IonHeader>
      <IonContent className="backgroundGraphics">
        <div className="columnContainer">
          <div className="bannerLeft">
            <img src={filter?.icon || ""} alt="img" />
            <div className="labelDiv">
              {filter?.label_lang?.[langCode]
                ? filter?.label_lang?.[langCode]
                : filter?.label}
            </div>
          </div>
          <div className="bg-grey">
            <div className="img-card">
              <div className="padding-box">
                <div className="flex-head">
                  <div className="container">
                    <h4 className="inner-title">
                      {filter?.label_lang?.[langCode]
                        ? filter?.label_lang?.[langCode]
                        : filter?.label}
                    </h4>
                  </div>
                </div>

                <div className="container">
                  <div className="tagsCloud">
                    {filter?.subFilter
                      ?.sort((a, b) => a.label.localeCompare(b.label))
                      ?.map((_item, index) => (
                        <IonItem key={index} lines="none" onClick={() => {
                          let data = {
                            filterType: "subtype",
                            filtername: _item.label,
                            filternameLang: _item?.label_lang?.[langCode]
                              ? _item?.label_lang?.[langCode]
                              : _item?.label,
                              key:_item?.subfilter||""
                          };
                          localStorage.setItem(
                            "filterUnderDetails",
                            JSON.stringify(data)
                          );
                          history.push(`/viewplacescard`);
                        }}>
                          {_item?.label_lang?.[langCode]
                            ? _item?.label_lang?.[langCode]
                            : _item?.label}
                        </IonItem>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
      <FooterMenu history={history} langCode={langCode}/>
    </IonPage>
  );
};

export default withRouter(ViewDetails);
